import React from "react";
import PostPreview from "../PostPreview";
import CategoryPreview from "../CategoryPreview";

class CategoryIndex extends React.Component {
  getCategoryIndex() {
//    const categoryIndex = [];

//    this.props.postEdges.forEach(postEdge => {
//      postList.push({
//        path: postEdge.node.fields.slug,
//        tags: postEdge.node.frontmatter.tags,
//        cover: postEdge.node.frontmatter.cover,
//        title: postEdge.node.frontmatter.title,
//        date: postEdge.node.fields.date,
//        excerpt: postEdge.node.excerpt,
//        timeToRead: postEdge.node.timeToRead
//      });
//    });

    const categoryDirectory = [
        {
          slug: "/categories/recipe",
          tags: ["recipe"],
          cover: "Recipe_Header.jpg",
          title: "Gingerbread Recipes",
          date: "30-05-2019",
          excerpt: "Gingerbread and Icing Recipes that are Second to None",
          timeToRead: "0"
        },
        {
          slug: "/categories/gingerbread-house",
          tags: ["gingerbread_house"],
          cover: "GingerbreadHouse_Header.jpg",
          title: "Gingerbread Houses",
          date: "19-05-2019",
          excerpt: "Enter the World of Rockstar Gingerbread House Building",
          timeToRead: "0"
        },
        {
            slug: "/categories/gingerbread-party",
            tags: ["gingerbread_party"],
            cover: "GingerbreadParty_Overview_Header.jpg",
            title: "Gingerbread House Parties",
            date: "19-05-2019",
            excerpt: "Host a Decorating Party that will be the Highlight of the Season",
            timeToRead: "0"
        },        
        {
            slug: "/categories/cookies",
            tags: ["cookie"],
            cover: "MakeAwesomeCookies_Header_Publish.png",
            title: "Gingerbread Cookies",
            date: "19-05-2019",
            excerpt: "How to Become a Gingerbread Cookie Jedi Master",
            timeToRead: "0"
        },
        {
            slug: "/categories/gingerbread-project",
            tags: ["gingerbread_project"],
            cover: "TinyMugHouses_Header_Publish.png",
            title: "Other Gingerbread Projects",
            date: "19-05-2019",
            excerpt: "So Many Other Things to be Done with Gingerbread",
            timeToRead: "0"
        }
    ];

    const categoryIndex = [];

    categoryDirectory.forEach(categoryDirectory => {
      categoryIndex.push({
        path: categoryDirectory.slug,
        tags: categoryDirectory.tags,
        cover: categoryDirectory.cover,
        title: categoryDirectory.title,
        date: categoryDirectory.date,
        excerpt: categoryDirectory.excerpt,
        timeToRead: categoryDirectory.timeToRead
      });
    });

    return categoryIndex;
  }
  render() {
    const categoryIndex = this.getCategoryIndex();
    return (
      <div className="md-grid md-grid--no-spacing md-cell--middle">
        <div className="md-grid md-cell--8 mobile-fix">
          {categoryIndex.map(post => (
            <CategoryPreview key={post.title} postInfo={post} />
          ))}
        </div>
      </div>
    );
  }
}

export default CategoryIndex;
