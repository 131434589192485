import React, { Component } from "react";
import Card from "react-md/lib/Cards/Card";
import CardText from "react-md/lib/Cards/CardText";
import UserLinks from "../UserLinks";
import config from "../../../data/SiteConfig";
import "./Welcome.scss";

//<UserLinks labeled config={config} />
//<p className="welcome-text md-body-1">{config.welcomeMessage}</p>

class WelcomeMessage extends Component {
  render() {
    return (
      <div className="welcome-container md-grid mobile-fix">
        <Card className="md-grid md-cell--8">
          <div className="welcome-wrapper">
            <img
              src={config.welcomeLogo}
              className="welcome-img"
              alt={config.userName}
              style={{width:"10rem", height:"10rem"}}
            />
            <CardText>
              <p className="welcome-text md-body-1">
			  Welcome to Gingerbox!  We are here to share our love for making gingerbread houses and gingerbread cookies with you, as well as providing you with a few tips and tricks we've picked up along the way.
			  </p>
              <p className="welcome-text md-body-1">
			  Please note that this site is still under construction, so expect to find a few bumps and hiccups along the way.  Comments and contacts are not yet enabled, so if you have any comments, questions, or suggestions about what you would like to see here, please let us know at gingerbox@gingerbox.net.  Thanks!
			  </p>
            </CardText>
            
          </div>
        </Card>
      </div>
    );
  }
}

export default WelcomeMessage;
